import {createRouter, createWebHistory} from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import campaignSelectionMiddleware from "@/middleware/campaignSelectionMiddleware";
import loginMiddleware from "@/middleware/loginMiddleware";
import SubscriptionComplete from "@/views/SubscriptionComplete";
import UserWifiActive from "@/views/UserWifiActive";
import UserAlreadySubscribed from "@/views/UserAlreadySubscribed";
import SubscriptionError from "@/views/SubscriptionError";
// import store from '../store/index'
// import {CampaignAssetMapping} from "@/config/campaignAssetMapping";

const routes = [
    {
        path: "/:pathMatch(.*)*",
        name: 'NotFound',
        redirect: '/landing',
        beforeEnter: async (to, from, next) => {
            campaignSelectionMiddleware();
            await loginMiddleware(to, from, next);
        },
    },
    {
        path: '/',
        name: 'landing',
        component: LandingPage,
        beforeEnter: async (to, from, next) => {
            campaignSelectionMiddleware();
            await loginMiddleware(to, from, next);
        },
    },
    {
        path: '/subscription-complete',
        name: 'SubscriptionComplete',
        component: SubscriptionComplete,
        beforeEnter: async () => {
            campaignSelectionMiddleware();
        },
    },
    {
        path: '/user-wifi-active',
        name: 'UserWifiActive',
        component: UserWifiActive,
        beforeEnter: async () => {
            campaignSelectionMiddleware();
        },
    },
    {
        path: '/user-already-subscribed',
        name: 'UserAlreadySubscribed',
        component: UserAlreadySubscribed,
        beforeEnter: async () => {
            campaignSelectionMiddleware();
        },
    },
    {
        path: '/subscription-error',
        name: 'SubscriptionError',
        component: SubscriptionError,
        beforeEnter: async () => {
            campaignSelectionMiddleware();
        },
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// router.beforeEach(() => {
//   if (!store.state.campaignConfig.brand) store.commit('setCampaignConfig', CampaignAssetMapping.get("0"));
// })

router.beforeEach((to, from, next) => {
    /*const utagData = {
        page_name: window.location.host,
        page_section: to.fullPath,
        page_category: '',
        page_subcategory: '',
        page_type: 'Article',
        page_title: document.title,
        page_url: window.location.href,
        page_referrer: document.referrer,
        customer_id: '',
        customer_username: '',
        customer_country: window.navigator.language.split('-').reverse()[0],
        site_region: window.navigator.language,
        article_id: '',
        article_name: '',
        video_id: '',
        video_name: '',
        video_player: '',
        user_platform: window.clientInformation.platform,
        // connection_type: window.clientInformation.connection.effectiveType,
        team_id: '',
        page_event: '',
        ad_requests: '',
        page_channel: '',
        page_platform: '',
        am_report_suite: '',
        page_section_lvl1: '',
        page_section_lvl2: '',
        page_section_lvl3: '',
        page_section_lvl4: '',
        page_section_lvl5: ''
    };

    console.log('utag_data: ', utagData);
    const a = 'https://tags.tiqcdn.com/utag/vodafone/za-nxtvl-main/prod/utag.js';
    const b = document;
    const c = 'script';
    const d = b.createElement(c);
    d.src = a;
    d.type = 'text/java' + c;
    d.async = true;
    const e = b.getElementsByTagName(c)[0];
    e.parentNode.insertBefore(d, e);
    window.utag_data = utagData;*/

    next();
})

export default router
