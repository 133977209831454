import store from '../store/index'
import CampaignService from "@/services/campaignService";

export default async (to, from, next) => {
    store.commit('setIsLoading', true);

    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('token');
    const headerEnrichmentAttempted = urlParams.get('headerEnrichmentAttempted');

    const campaignId = urlParams.get('cid') || urlParams.get('campaignId');
    const acquisitionChannel = urlParams.get('acquisitionChannel') || 'GTM';
    const revenuePartnerId = urlParams.get('revenuePartnerId');
    const impressionRecord = {campaignId, acquisitionChannel, revenuePartnerId, onWifi: 0};

    if (headerEnrichmentAttempted && !encryptedMsisdn) {
        impressionRecord.onWifi = 1;
        await CampaignService.trackImpression(impressionRecord);
        routeWithQueryStringParams(to, from, next,'UserWifiActive');
    } else if (encryptedMsisdn) {
        const headerEnrichedLoginResponse = await CampaignService.headerEnrichedLogin(encryptedMsisdn);
        if (headerEnrichedLoginResponse.data.token) {
            await setUserData(headerEnrichedLoginResponse.data.token, encryptedMsisdn, impressionRecord, to, from, next);
        } else {
            const queryParamObject = getQueryStringParams(from, to);
            const targetUrl = `http://thevcfl.com?${getQueryParamsString(queryParamObject)}&redirectURL=https://campaign.thevcfl.com`;
            window.open(targetUrl, '_self');
            next();
        }
    } else {
        const queryParamObject = getQueryStringParams(from, to);
        const targetUrl = `http://thevcfl.com?${getQueryParamsString(queryParamObject)}&redirectURL=https://campaign.thevcfl.com`;
        window.open(targetUrl, '_self');
    }
}

async function setUserData(token, encryptedMsisdn, impressionRecord, to, from, next) {
    const getUserByTokenResponse = await CampaignService.getUserMsisdnByToken(token);
    const msisdn = getUserByTokenResponse.recordset[0]?.Msisdn;
    impressionRecord.msisdn = msisdn;
    await CampaignService.trackImpression(impressionRecord);
    const loginResponse = await CampaignService.getUser(msisdn);

    if (loginResponse.data.grantAccess) {
        store.commit('setUser', {msisdn, token, encryptedMsisdn});
        store.commit('setEntryPortalHost', `https://www.vodacomfantasy.com?encryptedMsisdn=${encryptedMsisdn}`);
        routeWithQueryStringParams(to, from, next, 'UserAlreadySubscribed');
    } else {
        store.commit('setUser', {msisdn, token, encryptedMsisdn});
        store.commit('setEntryPortalHost', `https://www.vodacomfantasy.com?encryptedMsisdn=${encryptedMsisdn}`);
        store.commit('setIsLoading', false);
        routeWithQueryStringParams(to, from, next);
    }
}

function routeWithQueryStringParams(to, from, next, name) {
    const queryParams = getQueryStringParams(to, from);

    if (queryParams && name) next({name: name, query: queryParams});
    else if (name) next({name: name});
    else next();
}

function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}

function getQueryStringParams(to, from) {
    if (hasQueryParams(from)) return from.query;
    else if (to.redirectedFrom && hasQueryParams(to.redirectedFrom)) return to.redirectedFrom.query;
    else if (hasQueryParams(to)) return to.query;
}

function getQueryParamsString(queryParamObject) {
    let queryParamsString = '';
    for (const [key, value] of Object.entries(queryParamObject)) {
        if (queryParamsString === '') queryParamsString += `${key}=${value}`;
        else queryParamsString += `&${key}=${value}`;
    }
    return queryParamsString;
}
