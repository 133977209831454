<template>
  <div class="d-flex flex-row justify-content-center justify-content-md-end position-relative text px-4">
    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
      <img class="join-text-img mt-4 mb-1" src="../assets/join-text.png">
      <div class="secondary-header-text text-center mt-1 mx-2">
        Subscription service R3.00 p/day, First Day Free! To unsubscribe dial *135*997#. <a href="http://termsandconditions.vcfl.co.za/"><b><u>T's & C's</u></b></a>
      </div>
    </div>
  </div>
  <div class="side-block-left"></div>
  <button class="primary-button text join-now-button" v-on:click="onConfirmClicked">JOIN NOW</button>
  <div class="side-block-right"></div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import CampaignService from "@/services/campaignService";

export default {
  name: 'LandingPage',
  computed: {
    ...mapState(['campaignConfig', 'user']),
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async onConfirmClicked() {
      this.setIsLoading(true);
      await CampaignService.trackSubscribeClicked();
      await CampaignService.doiTwo(this.user.msisdn);
      const response = await CampaignService.subscribe(this.user.msisdn, this.campaignConfig.campaignId, this.$route.query);
      if (response.data.result) {
        this.$router.push({ path: '/subscription-complete', query: this.$route.query });
      } else {
        this.$router.push({ path: '/subscription-error', query: this.$route.query });
      }
      this.setIsLoading(false);
    },
    getQueryParamsString() {
      let queryParamsString = '';
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (queryParamsString === '') queryParamsString += `${key}=${value}`;
        else queryParamsString += `&${key}=${value}`;
      }
      return queryParamsString;
    }
  },
  async mounted() {
    this.setIsLoading(false);
    if (!this.user) {
      const targetUrl = `http://thevcfl.com?${this.getQueryParamsString()}&redirectURL=https://campaign.thevcfl.com`;
      window.open(targetUrl, '_self');
    }
    else {
      this.setIsLoading(true);
      await CampaignService.doiOne(this.user.msisdn);
      this.setIsLoading(false);
    }
  }
}
</script>

<style scoped lang="scss">
.join-text-img {
  width: 90%;
}

.text {
  color: #e60000;
  font-size: 0.8rem;
}

.join-now-button {
  position: absolute;
  top: calc(30vh - 2.3rem);
  left: 50%;
  transform: translate(-50%);
  width: 75%;
  height: 4.6rem;
  padding: 0.5rem 0.5rem;
  background: #e60000;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 2.2rem;
  font-weight: 500;
}

.side-block-left {
  position: absolute;
  left: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}

.side-block-right {
  position: absolute;
  right: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}
</style>
