<template>
  <div class="d-flex flex-row justify-content-center justify-content-md-end position-relative text px-4">
    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
      <div class="secondary-header-text text-center my-2 mx-2">
        Thank You!
      </div>
      <img class="join-text-img mb-1" src="../assets/enter-text.png">
    </div>
  </div>
  <div class="side-block-left"></div>
  <button class="primary-button text join-now-button" v-on:click="onPlayNowClicked">PLAY NOW</button>
  <div class="side-block-right"></div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: 'SubscriptionComplete',
  computed: {
    ...mapState(['campaignConfig', 'entryPortalHost']),
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    getQueryParamsString() {
      let queryParamsString = '';
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (queryParamsString === '') queryParamsString += `${key}=${value}`;
        else queryParamsString += `&${key}=${value}`;
      }
      return queryParamsString;
    },
    async onPlayNowClicked() {
      const targetUrl = `${this.entryPortalHost}?${this.getQueryParamsString()}`;
      window.open(targetUrl, '_self');
    }
  }
}
</script>

<style scoped lang="scss">
.join-text-img {
  width: 90%;
}

.text {
  color: #e60000;
  font-weight: bold;
  font-size: 1.6rem;
}

.join-now-button {
  position: absolute;
  top: calc(30vh - 2.3rem);
  left: 50%;
  transform: translate(-50%);
  width: 75%;
  height: 4.6rem;
  padding: 0.5rem 0.5rem;
  background: #e60000;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 2.2rem;
  font-weight: 500;
}

.side-block-left {
  position: absolute;
  left: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}

.side-block-right {
  position: absolute;
  right: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}
</style>


