<template>
  <div class="d-flex flex-row justify-content-center justify-content-md-end position-relative text px-4">
    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
      <div class="secondary-header-text text-center mt-5 mx-2">
        Apologies we were unable to subscribe you!
      </div>
      <div class="secondary-header-text text-center mt-3 mx-2">
        Please click the retry button below.
      </div>
    </div>
  </div>
  <div class="side-block-left"></div>
  <button class="primary-button text join-now-button" v-on:click="onRetryClicked">RETRY</button>
  <div class="side-block-right"></div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import CampaignService from "@/services/campaignService";

export default {
  name: 'SubscriptionError',
  computed: {
    ...mapState(['campaignConfig', 'user']),
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async onRetryClicked() {
      this.setIsLoading(true);
      await CampaignService.doiTwo(this.user.msisdn);
      const response = await CampaignService.subscribe(this.user.msisdn, this.campaignConfig.campaignId, this.$route.query);
      if (response.data.result) {
        this.$router.push({path: '/subscription-complete', query: this.$route.query});
      } else {
        this.$router.push({path: '/subscription-error', query: this.$route.query});
      }
      this.setIsLoading(false);
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  color: #e60000;
  font-weight: bold;
  font-size: 1.3rem;
}

.join-now-button {
  position: absolute;
  top: calc(30vh - 2.3rem);
  left: 50%;
  transform: translate(-50%);
  width: 75%;
  height: 4.6rem;
  padding: 0.5rem 0.5rem;
  background: #e60000;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 2.2rem;
  font-weight: 500;
}

.side-block-left {
  position: absolute;
  left: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}

.side-block-right {
  position: absolute;
  right: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}
</style>


