<template>
  <div>
    <div class="game-background"
         :style="{ backgroundImage: 'url(' + require(`./assets/${campaignConfig.brand}/${campaignConfig.backgroundImagePath}`) + ')'}">
    </div>
    <div class="row justify-content-center align-items-center loading-wrapper" v-if="isLoading">
      <img src="./assets/loading-gif.gif" class="loading-gif"/>
    </div>
  </div>
  <router-view/>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {},
  computed: {
    ...mapState(['campaignConfig', 'isLoading']),
  }
}
</script>

<style lang="scss">
.game-background {
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 70vh;
  position: fixed;
  //background-position: center;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-overflow-scrolling: touch !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.loading-wrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 110vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
}
</style>
