import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: true,
    campaignConfig: undefined,
    user: undefined,
    entryPortalHost: undefined,
    queryStringParams: undefined
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setCampaignConfig(state, campaignConfig) {
      state.campaignConfig = campaignConfig;
    },
    setUser(state, user) {
      state.user = user;
    },
    setEntryPortalHost(state, entryPortalHost) {
      state.entryPortalHost = entryPortalHost;
    },
    setQueryStringParams(state, queryStringParams) {
      state.queryStringParams = queryStringParams;
    }
  }
})
