import axios from "axios";

export default class CampaignService {
    static async headerEnrichedLogin(encryptedMsisdn) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_HOST}/user-authentication/encrypted-msisdn-login`,
            data: {
                encryptedMsisdn: encryptedMsisdn,
            }
        })
    }

    static async getUser(msisdn) {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_HOST}/user?msisdn=${msisdn}`,
        });
    }

    static async trackImpression(impressionRecord) {
        const {msisdn, campaignId, acquisitionChannel, revenuePartnerId, onWifi} = impressionRecord;
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_HOST}/marketing/track-impression`,
            data: {
                msisdn,
                campaignId,
                acquisitionChannel,
                revenuePartnerId,
                onWifi
            }
        });
    }

    static async trackSubscribeClicked() {
        return await axios({
            method: 'get',
            url: `https://bitly.vodacomfantasy.com/BkW5q`
        });
    }

    static async getUserMsisdnByToken(token) {
        const user = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_HOST}/user-authentication/get-msisdn-from-token`,
            data: {
                token
            }
        });

        return user.data
    }

    static async doiOne(msisdn) {
        return await axios.get(`${process.env.VUE_APP_VODACOM_INTEGRATION_HOST}/doi-one?msisdn=${msisdn}&channel=FantasyLeague-Web&price=300&scheme=Daily`);
    }

    static async doiTwo(msisdn) {
        return await axios.get(`${process.env.VUE_APP_VODACOM_INTEGRATION_HOST}/doi-two?msisdn=${msisdn}&channel=FantasyLeague-Web&price=300&scheme=Daily`);
    }

    static async subscribe(msisdn, campaignId, queryParams) {
        const revenuePartnerId = queryParams.revenuePartnerId ? queryParams.revenuePartnerId : 1;
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_HOST}/subscription`,
            params: {
                msisdn: msisdn,
                action: 'Subscription',
                revenuePartnerId,
                campaignId,
                ...queryParams
            }
        })
    }
}
