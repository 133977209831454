export const CampaignAssetMapping = new Map();

CampaignAssetMapping.set("0", {
    campaignId: 0,
    backgroundImagePath: window.innerWidth > 768 ? 'vodacom-background-0-desktop.png' : 'vodacom-background-0-mobile.png',
    brand: 'vodacom'
});

CampaignAssetMapping.set("100529", {
    campaignId: 100529,
    backgroundImagePath: window.innerWidth > 768 ? 'vodacom-bg-desktop.jpg' : 'vodacom-bg-desktop.jpg',
    brand: 'vodacom'
});
