<template>
  <div class="d-flex flex-row justify-content-center justify-content-md-end position-relative text px-4">
    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
      <img class="wifi-icon mt-3" src="../assets/wifi-icon.png">
      <div class="primary-header-text text-center mt-3 fw-bold">
        You are on wifi, please turn off your wifi and click "JOIN NOW".
      </div>
      <div class="primary-header-text text-center mt-1 fw-bold">
        Or enter your number below.
      </div>
      <div class="secondary-header-text text-center mt-1 mx-2">
        Subscription service R3.00 p/day, First Day Free! To unsubscribe dial *135*997#. <a href="http://termsandconditions.vcfl.co.za/"><b><u>T's & C's</u></b></a>
      </div>
    </div>
  </div>
  <div class="side-block-left"></div>
  <button class="primary-button text join-now-button" v-on:click="onJoinNowClicked">JOIN NOW</button>
  <div class="side-block-right"></div>

  <div class="manual-msisdn-input">
    <div class="flex-wrap text-center text-box p-2 pt-1 msisdn-input-container">
      <input type="text" v-model="msisdn" v-on:change="msisdnConversion" class="text-content msisdn-input"
             placeholder="CELLPHONE NUMBER" required/>
    </div>
    <div class="flex-wrap text-center text-box" v-if="!validMsisdn">
      <span class="text-content error-message">Please enter a valid msisdn.</span>
    </div>
    <div class="d-flex justify-content-center p-1">
      <button class="confirm-button mt-0" @click="onConfirmClicked">Confirm</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import CampaignService from "../services/campaignService";
export default {
  name: 'UserWifiActive',
  data() {
    return {
      msisdn: undefined,
      msisdnSubmitted: false,
      validMsisdn: true,
      transactionID: undefined,
      errorMessage: false,
    }
  },
  computed: {
    ...mapState(['campaignConfig']),
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    onJoinNowClicked() {
      const targetUrl = `http://thevcfl.com?${this.getQueryParamsString()}&redirectURL=https://campaign.thevcfl.com`;
      window.open(targetUrl, '_self');
    },
    getQueryParamsString() {
      let queryParamsString = '';
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (queryParamsString === '') queryParamsString += `${key}=${value}`;
        else queryParamsString += `&${key}=${value}`;
      }
      return queryParamsString;
    },
    msisdnConversion() {
      if (this.msisdn.startsWith('0')) {
        this.msisdn = this.msisdn.slice(1);
        const saNumber = '27';
        this.msisdn = parseInt(saNumber + this.msisdn, 10);
      } else if (this.msisdn.startsWith('+')) {
        this.msisdn = this.msisdn.slice(1);
      }
    },
    async onConfirmClicked() {
      if (this.msisdn.toString().length === 11) {
        const loginResponse = await CampaignService.getUser(this.msisdn);
        if (loginResponse.data.grantAccess) {
          this.$router.push({ path: '/user-already-subscribed', query: this.$route.query });
        } else {
          this.setIsLoading(true);
          await CampaignService.trackSubscribeClicked();
          await CampaignService.doiTwo(this.msisdn);
          const response = await CampaignService.subscribe(this.msisdn, this.campaignConfig.campaignId, this.$route.query);
          if (response.data.result) {
            this.$router.push({path: '/subscription-complete', query: this.$route.query});
          } else {
            this.$router.push({path: '/subscription-error', query: this.$route.query});
          }
          this.setIsLoading(false);
        }


      } else {
        this.validMsisdn = false;
      }
    },
  },
  mounted() {
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.wifi-icon {
  width: 4rem;
}

.text {
  color: #e60000;
  font-size: 0.8rem;
}

.primary-header-text {
  font-size: 1rem;
}

.secondary-header-text {
  font-size: 0.5rem;
}

.join-now-button {
  position: absolute;
  top: calc(30vh - 2.3rem);
  left: 50%;
  transform: translate(-50%);
  width: 75%;
  height: 4.6rem;
  padding: 0.5rem 0.5rem;
  background: #e60000;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 2.2rem;
  font-weight: 500;
}

.side-block-left {
  position: absolute;
  left: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}

.side-block-right {
  position: absolute;
  right: 0;
  top: calc(30vh - 1rem);
  width: 2rem;
  height: 2rem;
  background: #e60000;
}

.manual-msisdn-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 1rem;
}

.confirm-button {
  width: 75%;
  height: 4.6rem;
  padding: 0.5rem 0.5rem;
  background: #e60000;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-size: 2.2rem;
  font-weight: 500;
}

.msisdn-input {
  border: 1px solid #dadada;
  border-radius: 10px;
  width: 80%;
  padding: 8px 40px;
  text-align: center;
  font-size: 14px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.error-message {
  color: #FFFFFF;
}

.error-message-text {
  color: red;
}
</style>
