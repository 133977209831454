import store from '../store/index'
import {CampaignAssetMapping} from "@/config/campaignAssetMapping";

export default async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignId = urlParams.get('cid') || urlParams.get('campaignId');

    if (!campaignId) {
        return window.open('http://thevcfl.com', '_self');
    } else {
        if (CampaignAssetMapping.has(campaignId)) {
            const campaignConfig = CampaignAssetMapping.get(campaignId);
            store.commit('setCampaignConfig', campaignConfig);
        } else {
            store.commit('setCampaignConfig', CampaignAssetMapping.get("0"));
        }
    }
}
